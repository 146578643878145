/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    a: "a",
    h2: "h2",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Modules allow Quill's behavior and functionality to be customized. Several officially supported modules are available to pick and choose from, some with additional configuration options and APIs. Refer to their respective documentation pages for more details."), "\n", React.createElement(_components.p, null, "To enable a module, simply include it in Quill's configuration."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const quill = new Quill('#editor', {\n  modules: {\n    'history': {          // Enable with custom configurations\n      'delay': 2500,\n      'userOnly': true\n    },\n    'syntax': true        // Enable with default configuration\n  }\n});\n")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "/docs/modules/clipboard/"
  }, "Clipboard"), ", ", React.createElement(_components.a, {
    href: "/docs/modules/keyboard/"
  }, "Keyboard"), ", and ", React.createElement(_components.a, {
    href: "/docs/modules/history/"
  }, "History"), " modules are required by Quill and do not need to be included explictly, but may be configured like any other module."), "\n", React.createElement(_components.h2, null, "Extending"), "\n", React.createElement(_components.p, null, "Modules may also be extended and re-registered, replacing the original module. Even required modules may be re-registered and replaced."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const Clipboard = Quill.import('modules/clipboard');\nconst Delta = Quill.import('delta');\n\nclass PlainClipboard extends Clipboard {\n  convert(html = null) {\n    if (typeof html === 'string') {\n      this.container.innerHTML = html;\n    }\n    let text = this.container.innerText;\n    this.container.innerHTML = '';\n    return new Delta().insert(text);\n  }\n}\n\nQuill.register('modules/clipboard', PlainClipboard, true);\n\n// Will be created with instance of PlainClipboard\nconst quill = new Quill('#editor');\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Note: This particular example was selected to show what is possible. It is often easier to just use an API or configuration the existing module exposes. In this example, the existing Clipboard's ", React.createElement(_components.a, {
    href: "/docs/modules/clipboard/#addmatcher"
  }, "addMatcher"), " API is suitable for most paste customization scenarios.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
